import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
  isPlatform,
} from '@ionic/react';
import {useContext} from 'react';
import {useLocation} from 'react-router-dom';

import { AppContext, getEnvironment, getSites, getUser } from '../State';

import './Menu.css';
import {sitecontrollerGlobals} from '../variables';

interface MenuPage {
  url?: string;
  icon: any;
  secondaryIcon?: any;
  secondaryIconColor?: string;
  title: string;
  external?: boolean;
  onClick?: any;
}

const Menu: React.FC = () => {
  const {state} = useContext(AppContext);
  const location = useLocation();

  const sites: any[] = getSites(state);
  const user: any = getUser(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  const renderItem = (page: MenuPage) => {
    const item = (
      <IonItem className={location.pathname === page.url ? 'selected' : ''} routerLink={page.external ? undefined : page.url} routerDirection="none" lines="none" detail={false} onClick={page.onClick}>
        <div slot="start" className="menu-item-icon">
          <FontAwesomeIcon icon={page.icon || ['fad', 'angry']} size="lg" fixedWidth={true}/>
        </div>
        <IonLabel>{page.title}</IonLabel>
        {page.secondaryIcon ? (
          <div slot="end" className="menu-item-secondary-icon">
            <FontAwesomeIcon className={page.secondaryIconColor} icon={page.secondaryIcon} fixedWidth={true}/>
          </div>
        ) : null}
      </IonItem>
    );

    return (
      <IonMenuToggle autoHide={false}>
        {page.external ? <a href={page.url} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>{item}</a> : item}
      </IonMenuToggle>
    );
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonRow>
          <IonCol>
            {user.image
              ? <IonImg class="menu-logo" src={`${environmentOptions.url}/media/uploads/images/${user.image}`}/>
              : <IonImg class="menu-logo" src="assets/logo.png"/>
            }
          </IonCol>
        </IonRow>
        <IonList>
          {sites.filter(site => site.status).map((site, index) => {
            return (
              <div key={index}>
                {renderItem({
                  title: site.name,
                  icon: ['fal', 'map-marker-alt'],
                  url: `/site/${site.id}`,
                  secondaryIcon: site.status ? ['fal', 'exclamation-triangle'] : null,
                  secondaryIconColor: 'color-danger',
                })}
              </div>
            );
          })}
          {renderItem({title: 'My sites', icon: ['fal', 'ellipsis-h'], url: '/'})}
          <IonNote className="header"/>
          {renderItem({title: 'Announcements', icon: ['fal', 'file-alt'], url: '/announcements'})}
          {user.admin ? renderItem({title: 'All sites', icon: ['fad', 'th'], url: '/sites/all', secondaryIcon: ['fas', 'shield']}) : null}
          {renderItem({title: 'Settings', icon: ['fad', 'user-cog'], url: '/settings'})}
          {user.admin ? renderItem({title: 'Admin panel', icon: ['fad', 'toolbox'], url: `${environmentOptions.url}/admin`, secondaryIcon: ['fas', 'shield'], external: true}) : null}
          {renderItem({title: 'Contact', icon: ['fad', 'envelope'], url: 'mailto:info@elense.nl', external: true})}
          <IonNote className="header"/>
          {isPlatform('android') && (
            <div>
              {/*renderItem({title: 'DMSS', icon: ['fab', 'app-store-ios'], url: 'android-app://com.mm.android.DMSS', external: true})*/}
              {user.apps.canSeeExacqMobile ? renderItem({title: 'Exacq Mobile', icon: ['fab', 'app-store-ios'], url: 'android-app://com.exacq.nvrclient', external: true}) : null}
              {/*renderItem({title: 'Hik-Connect', icon: ['fab', 'app-store-ios'], url: 'android-app://com.hikvision.hikconnect', external: true})*/}
              {user.apps.canSeeHiLook ? renderItem({title: 'HiLook', icon: ['fab', 'app-store-ios'], url: 'android-app://com.mcu.hilook', external: true}) : null}
            </div>
          )}
          {isPlatform('ios') && (
            <div>
              {/*renderItem({title: 'DMSS', icon: ['fab', 'app-store-ios'], url: 'dmss://', external: true})*/}
              {/*renderItem({title: 'Exacq Mobile', icon: ['fab', 'app-store-ios'], url: 'no url scheme', external: true})*/}
              {/*renderItem({title: 'Hik-Connect', icon: ['fab', 'app-store-ios'], url: 'no url scheme', external: true})*/}
              {user.apps.canSeeHiLook ? renderItem({title: 'HiLook', icon: ['fab', 'app-store-ios'], url: 'hilook://', external: true}) : null}
            </div>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
