import {
  IonButton,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import {useCallback, useContext, useEffect, useState} from 'react';

import {AppContext, loggedIn} from '../State';
import {sitecontrollerGlobals} from '../variables';
import autoFetcher from '../services/Autofetcher';

import './Login.css';

const Login: React.FC = () => {
  const {dispatch} = useContext(AppContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [handlingLogin, setHandlingLogin] = useState(false);

  const [present] = useIonAlert();

  const handleLogin = useCallback(() => {
    if ('' === email || '' === password || handlingLogin) {
      return;
    }

    const environment = email.includes(':') ? email.substring(0, email.indexOf(':')) : 'prod';
    const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

    setHandlingLogin(true);

    const loginData = {
      username: email.includes(':') ? email.substring(email.indexOf(':') + 1) : email,
      password: password,
    };

    fetch(`${environmentOptions.url}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    })
      .then(response => response.json())
      .then(response => {
        if (!response.message) {
          dispatch(loggedIn(response.token, response.user, environment));

          autoFetcher.setRefreshRate(environmentOptions.refreshRate);

          return;
        }

        present({
          header: 'Failed to login',
          message: response.message,
          buttons: [
            {text: 'Dismiss'},
          ],
        });

        setHandlingLogin(false);
      })
      .catch(() => {
        setHandlingLogin(false);
      })
    ;
  }, [dispatch, email, handlingLogin, password, present]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();

        handleLogin();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleLogin]);

  return (
    <IonPage className="login-page">
      <IonContent fullscreen>
        <IonImg class="login-logo" src="assets/logo.png"/>
        <IonItem className="ion-no-padding">
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            type="email"
            value={email}
            onIonChange={(e) => setEmail(e.detail.value!)}
          />
        </IonItem>
        <IonItem className="ion-no-padding">
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            type="password"
            value={password}
            onIonChange={(e) => setPassword(e.detail.value!)}
          />
        </IonItem>
        <IonButton type="submit" expand="block" disabled={'' === email || '' === password || handlingLogin} onClick={handleLogin} style={{marginTop: '1em'}}>
          Login
        </IonButton>
        {!isPlatform('ios') ? (
          <div className="ion-text-center ion-margin">
            <a href="https://www.elense.nl/">Elense</a> | <a href="mailto:info@elense.nl">Contact</a>
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default Login;
