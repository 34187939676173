import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRow,
  IonToolbar, isPlatform
} from '@ionic/react';
import {useContext, useEffect, useState} from 'react';

import ChangePasswordForm from '../components/ChangePasswordForm';
import NotificationsForm from '../components/NotificationsForm';
import { AppContext, getAuthToken, getEnvironment, getUser, logout } from '../State';
import {fillStorage} from '../Storage';
import {sitecontrollerGlobals} from '../variables';

const Settings: React.FC = () => {
  const {dispatch, state} = useContext(AppContext);

  const authToken = getAuthToken(state);
  const [webPushSubscription, setWebPushSubscription] = useState(null);
  const user = getUser(state);
  const environment = getEnvironment(state);
  const environmentOptions = (sitecontrollerGlobals.environments as any)[environment];

  useEffect(() => {
    let i = 0;

    const webPushInterval = setInterval(() => {
      const originalWebPushSubscription = (global as any).webPushSubscription;

      if (originalWebPushSubscription) {
        setWebPushSubscription(originalWebPushSubscription);

        clearInterval(webPushInterval);
      }

      if (i > 100) {
        clearInterval(webPushInterval);
      }

      ++i;
    }, 5000);
  }, []);

  const handleLogout = () => {
    if (webPushSubscription) {
      fetch(`${environmentOptions.url}/api/push/unregister`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          subscription: webPushSubscription,
          platform: 'web',
        }),
      });
    }

    const pushToken = (global as any).pushNotificationToken;

    if (pushToken) {
      fetch(`${environmentOptions.url}/api/push/unregister`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          token: pushToken,
          platform: isPlatform('ios') ? 'ios' : 'android',
        }),
      });
    }

    dispatch(logout());

    fillStorage(null);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" fullscreen>
        <div style={{display: 'flex', marginBottom: '1em', padding: '1em', alignItems: 'center'}}>
          {user.image ? <img className="user-image" src={`${environmentOptions.url}/media/uploads/images/${user.image}`} alt={user.company}/> : null}
          <div style={{marginLeft: '1em'}}>
            <strong>{user.name}</strong>
            <div>{user.company}</div>
          </div>
          <div className="ion-hide-lg-down" style={{marginLeft: '2em'}}>
            <IonButton color="primary" size="small" onClick={handleLogout}>Log out</IonButton>
          </div>
        </div>
        <div className="ion-hide-lg-up">
          <IonList>
            <IonItem routerLink="/settings/changePassword">
              <div slot="start">
                <FontAwesomeIcon className="color-primary" icon={['fad', 'key']} fixedWidth/>
              </div>
              <IonLabel>Change password</IonLabel>
            </IonItem>
            <IonItem routerLink="/settings/notifications">
              <div slot="start">
                <FontAwesomeIcon className="color-primary" icon={['fad', 'bell']} fixedWidth/>
              </div>
              <IonLabel>Notifications</IonLabel>
            </IonItem>
            <IonItem onClick={handleLogout} button detail={false}>
              <div slot="start">
                <FontAwesomeIcon className="color-primary" icon={['fad', 'user-times']} fixedWidth/>
              </div>
              <IonLabel>Log out</IonLabel>
            </IonItem>
          </IonList>
          <IonList style={{marginTop: '2em'}}>
            <IonItem onClick={() => window.open('mailto:info@elense.nl')} button detail={false}>
              <div slot="start">
                <FontAwesomeIcon className="color-primary" icon={['fad', 'envelope']} fixedWidth/>
              </div>
              <IonLabel>Contact</IonLabel>
            </IonItem>
          </IonList>
        </div>
        <div className="ion-hide-lg-down">
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="6">
                <IonCard style={{height: '100%'}}>
                  <IonCardHeader>
                    <IonCardTitle>Change password</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <ChangePasswordForm/>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol sizeLg="6">
                <IonCard style={{height: '100%'}}>
                  <IonCardHeader>
                    <IonCardTitle>Notifications</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <NotificationsForm/>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
